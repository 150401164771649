(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

var _navigationHandler = require('./parts/navigationHandler');

var _navigationHandler2 = _interopRequireDefault(_navigationHandler);

var _fixnavigation = require('./parts/fixnavigation');

var _fixnavigation2 = _interopRequireDefault(_fixnavigation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

document.addEventListener('DOMContentLoaded', function () {

	(0, _navigationHandler2.default)();
	(0, _fixnavigation2.default)();
});

},{"./parts/fixnavigation":2,"./parts/navigationHandler":3}],2:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

exports.default = function () {
	var the_header = document.querySelector('header');
	var the_body = document.body;
	var the_main = document.querySelector('main');

	window.addEventListener("scroll", function (event) {
		var scroll = this.scrollY;

		if (scroll >= the_header.clientHeight) {
			the_header.classList.add('on');
			the_body.style['margin-top'] = the_header.clientHeight + "px";
		} else if (scroll == 0) {
			the_header.classList.remove('on');
			the_body.style['margin-top'] = "0";
		}
	});
};

},{}],3:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

exports.default = function () {
	var mobileButton = document.querySelector('.mobileButton');
	var mobileNavBox = document.querySelector('.mobileNavBox');
	var mobileNav = document.querySelectorAll('.mobileNav > * a');

	mobileNav.forEach(function (event) {
		event.addEventListener('click', function () {
			mobileNavBox.classList.remove('on');
		});
	});
	mobileButton.addEventListener('click', function () {
		mobileNavBox.classList.toggle('on');
	});
};

},{}]},{},[1]);
